import { motion } from "framer-motion";
import { useState } from "react";

export const LanguageSwitch = () => {
  const savedLang = localStorage.getItem("lang");
  const [lang, setLang] = useState(savedLang ? savedLang : "en");

  function setLanguage(language) {
    if (lang !== language) {
      localStorage.setItem("lang", language);
      setLang(language);
      window.location.reload(false);
    }
  }

  return (
    <div className="language-switch">
      <motion.h2
        whileHover={{ scale: 1.2 }}
        className={"en" === lang ? "active" : ""}
        onClick={() => setLanguage("en")}
      >
        EN
      </motion.h2>
      <hr />
      <motion.h2
        whileHover={{ scale: 1.2 }}
        className={"pt" === lang ? "active" : ""}
        onClick={() => setLanguage("pt")}
      >
        PT
      </motion.h2>
    </div>
  );
};
