import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { wrap } from "@popmotion/popcorn";
import { Link } from "react-router-dom";

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 2, ...transition },
  },
};

const frameVariants = {
  hover: { scale: 0.95, transition },
  open: {
    opacity: 1,
    x: 0,
    transition: { duration: 1, ease: "backIn" },
  },
  closed: {
    opacity: 0,
    x: "50%",
    transition: { duration: 1, ease: "backOut" },
  },
};

const imageVariants = {
  hover: { scale: 1.2, transition },
  open: {
    opacity: 1,
    x: 0,
    transition: { duration: 1, ease: "backIn" },
  },
  closed: {
    opacity: 0,
    x: "50%",
    transition: { duration: 1, ease: "backOut" },
  },
};

export const FadingCaroussel = (props) => {
  const category = props.category;
  const imgs = category.images;
  const [imageIndex, setImageIndex] = useState(
    Math.floor(Math.random() * imgs.length)
  );

  const [newImage, setNewImage] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      var random = Math.floor(Math.random() * imgs.length);
      setNewImage(false);
      while (random === imageIndex)
        random = Math.floor(Math.random() * imgs.length);

      setTimeout(() => {
        setImageIndex(random);
        setNewImage(true);
      }, 600);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [imgs, imageIndex, newImage]);

  return (
    <motion.div className="thumbnail" variants={thumbnailVariants}>
      <motion.div
        className="frame"
        whileHover="hover"
        variants={frameVariants}
        animate={newImage ? "open" : "closed"}
      >
        <Link to={`/projects/${category.path}`}>
          <motion.div
            className="overlay"
            whileHover="hover"
            animate="initial"
            variants={{
              initial: {
                opacity: 0,
                transition: { duration: 0.2 },
              },
              hover: {
                backgroundColor: "rgb(0,0,0,0.5)",
                opacity: 1,
                scale: 1.4,
                transition: { duration: 0.5 },
              },
            }}
          >
            <h1 className="overlay-text">SEE ALL PROJECTS⟶</h1>
          </motion.div>
          <motion.img
            src={`/images/${imgs[imageIndex]}`}
            // src ={}
            variants={imageVariants}
          />
        </Link>
      </motion.div>
    </motion.div>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const variants = {
  initial: {
    zIndex: 0,
    x: 0,
    opacity: 1,
    transition: { duration: 0.5, ease: "easeIn" },
  },
  right: {
    x: 1000,
    opacity: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  left: {
    x: -1000,
    opacity: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
};

export const FadingCarousselWithArrows = ({ imgs, id }) => {
  const [[page, direction], setPage] = useState([1, 0]);
  const imageIndex = wrap(0, imgs.length, page);
  const paginate = (newDirection) => {
    setNewImage(true);
    setPage([page, newDirection]);

    setTimeout(() => {
      setPage([page + newDirection, newDirection]);
    }, 560);
  };
  const [newImage, setNewImage] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (direction === 1) {
      controls.start("right");
      setTimeout(() => {
        console.info("right");
        controls.start({ x: -1000, transition: { duration: 0 } });
      }, 550);
    } else if (direction === -1) {
      controls.start("left");
      setTimeout(() => {
        console.info("left");
        controls.start({ x: 1000, transition: { duration: 0 } });
      }, 550);
    }

    setTimeout(() => {
      controls.start("initial");
      setNewImage(false);
    }, 570);
  }, [controls, direction, newImage]);

  return (
    <>
      <motion.img
        src={`/images/${id}/${imgs[imageIndex]}`}
        variants={variants}
        initial="initial"
        animate={controls}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(-1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(1);
          }
        }}
      />

      <div
        className="prev"
        onClick={() => {
          paginate(-1);
        }}
      >
        ←
      </div>
      <div
        className="next"
        onClick={() => {
          paginate(1);
        }}
      >
        →
      </div>
    </>
  );
};
