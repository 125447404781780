import { useState } from "react";

export const OptionalFormSection = (props) => {
	if (props.visible) return <>{props.children}</>;
	else return null;
};

export const Contact = () => {
	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.target;
		const formData = new FormData(form);
		const formJson = JSON.stringify(Object.fromEntries(formData.entries()));
		let headersList = {
			Accept: "*/*",
			"Content-Type": "application/json",
		};

		const response = await fetch(
			"https://sendmail-ozmw336xka-ew.a.run.app",
			{
				method: form.method,
				body: formJson,
				headers: headersList,
			}
		);
		if (response.ok) alert("Email Sent!");
	};

	const [subject, setSubject] = useState("");

	const handleSubjectChange = (value) => {
		setSubject(value);
	};

	return (
		<div className="contact-container">
			<form
				onSubmit={handleSubmit}
				method="post"
				className="contact-form"
			>
				<h1>Contact Us!</h1>
				<input name="name" type="text" placeholder="Name" />
				<input name="email" type="email" placeholder="Email" />
				<div className="contact-form-row">
					<div className="contact-form-column">
						<label>Subject:</label>
						<select
							name="subject"
							value={subject}
							onChange={(e) =>
								handleSubjectChange(e.target.value)
							}
						>
							<option disabled>Select a subject</option>
							<option value="client">Business Offer</option>
							<option value="worker">Job Application</option>
						</select>
					</div>
					<OptionalFormSection visible={subject === "worker"}>
						<div className="contact-form-column">
							<label>Gender:</label>
							<select name="gender">
								<option disabled>Select a gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="other">Other</option>
							</select>
						</div>
					</OptionalFormSection>
				</div>
				<OptionalFormSection visible={subject === "worker"}>
					<input name="age" type="text" placeholder="Age" />
				</OptionalFormSection>
				<textarea name="text" rows="4" placeholder="Message" />
				{/* <input name="file" type="file" id="text" /> */}
				<div className="button-right-container">
					<button type="submit" className="contact-form-button">
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};
